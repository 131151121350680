<template>
  <div class="calendarPage__titleContainer">
    <base-button @click="$router.back()" :back="true">
      <horizontal-arrow />
    </base-button>
    <h1 class="calendarPage__title">{{ companyInformation.name }}</h1>
  </div>
  <div class="companyPage__steps mb-20">
    <div
      @click="selectCategory('overview')"
      class="companyPage__step"
      :class="{ 'companyPage__step-active': show.overview }"
    >
      {{ $t("accountOverviewPageOverview") }}
    </div>
    <div
      @click="selectCategory('billing')"
      class="companyPage__step"
      :class="{ 'companyPage__step-active': show.billing }"
    >
      {{ $t("billingInformation") }}
    </div>
    <div
      @click="selectCategory('platform')"
      class="companyPage__step"
      :class="{ 'companyPage__step-active': show.platform }"
    >
      {{ $t("platform") }}
    </div>
  </div>
  <div v-if="show.overview" class="companyPage__logoInformation">
    <div class="companyPage__logo">
      <company-logo
        v-if="!isLoading"
        @click="showLogoPopup()"
        size="300"
        :company-page="true"
        :company_slug="companyInformation.code"
        :image-name="companyInformation.logo"
      />
      <div class="accountPage__language ml-25">
        <vue-multiselect
          class="accountOverviewMultiselect languageSelect"
          v-if="companyInformation.language"
          @select="changeLanguage"
          :options="languageOptions"
          v-model="companyInformation.language"
          :searchable="false"
          :show-labels="false"
          placeholder=""
          openDirection="bottom"
          label="code"
        />
      </div>
    </div>
    <div class="companyPage__idWrapper">
      <div class="companyPage__idTitle">{{ $t("companyId") }}</div>
      <div class="companyPage__id">{{ companyInformation.code }}</div>
      <div class="companyPage__id">
        {{ $t("filterByLastSeen") }}:
        <data-last-seen :data="companyInformation.user.last_seen" />
      </div>
    </div>
  </div>
  <div v-if="show.overview" class="companyAddPage__companyInformation">
    <h5 class="companyAddPage__blockTitle">{{ $t("companyInformation") }}</h5>
    <div class="row">
      <div class="col-lg-6 pr-25">
        <base-input
          class="w-100 companyAddPage__input"
          v-model="companyInformation.name"
          :error="v$.companyInformation.name.$error"
          :error-message="v$.companyInformation.name.$errors"
        >
          {{ $t("companyName") }}
        </base-input>
        <base-input
          class="w-100 companyAddPage__input"
          type="number"
          v-model="companyInformation.number_of_employees"
        >
          {{ $t("numberOfEmployees") }}
        </base-input>
        <div class="accountPage__input companyAddPage__input">
          <span
            :class="{ 'baseInput__area-focus': companyInformation.activity }"
            class="baseInput__label"
          >
            {{ $t("fieldOfActivity") }}*
          </span>
          <vue-multiselect
            class="accountOverviewMultiselect"
            :options="companyActivitiesOptions"
            v-model="companyInformation.activity"
            :show-labels="false"
            placeholder=""
            openDirection="bottom"
            label="name"
            track-by="name"
          />
        </div>
        <base-input
          class="w-100 companyAddPage__input"
          read-only="true"
          v-model="companyInformation.code"
        >
          {{ $t("companyReference") }}
        </base-input>
      </div>
      <div class="col-lg-6 pl-25">
        <base-input
          class="w-100 companyAddPage__input"
          v-model="companyInformation.vat_number"
          >№ {{ $t("vat") }}
        </base-input>
        <base-input
          class="w-100 companyAddPage__input"
          v-model="companyInformation.country"
          >{{ $t("country") }}
        </base-input>
        <div class="d-flex justify-content-between">
          <base-input
            class="expertAddPage__input expertAddPage__input-small"
            v-model="companyInformation.city"
          >
            {{ $t("city") }}
          </base-input>
          <base-input
            class="expertAddPage__input expertAddPage__input-small"
            v-model="companyInformation.postal_code"
          >
            {{ $t("postalCode") }}
          </base-input>
        </div>
        <base-input
          class="w-100 companyAddPage__input"
          v-model="companyInformation.address"
        >
          {{ $t("accountOverviewPageAddress") }}
        </base-input>
      </div>
    </div>
    <h5 class="companyAddPage__blockTitle">{{ $t("contactInformation") }}</h5>
    <div class="row">
      <div class="col-lg-6 pr-25">
        <base-input
          class="w-100 companyAddPage__input"
          v-model="companyInformation.contact.first_name"
          :error="v$.companyInformation.contact.first_name.$error"
          :error-message="v$.companyInformation.contact.first_name.$errors"
        >
          {{ $t("firstName") }}
        </base-input>
        <base-input
          class="w-100 companyAddPage__input"
          v-model="companyInformation.user.email"
          :error="v$.companyInformation.user.email.$error"
          :error-message="v$.companyInformation.user.email.$errors"
        >
          {{ $t("accountOverviewPageEmail") }}
        </base-input>
        <div class="row">
          <div class="col-lg-6">
            <div class="accountPage__input companyAddPage__input">
              <span
                :class="{ 'baseInput__area-focus': companyInformation.gender }"
                class="baseInput__label"
              >
                {{ $t("accountOverviewPageGender") }}
              </span>
              <vue-multiselect
                class="accountOverviewMultiselect"
                :options="genderOptions"
                v-model="companyInformation.gender"
                :show-labels="false"
                placeholder=""
                openDirection="bottom"
                label="name"
                track-by="name"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <base-input
              class="w-100 companyAddPage__input"
              v-model="companyInformation.contact.office_number"
            >
              {{ $t("accountOverviewPageOffice") }}
            </base-input>
          </div>
        </div>
      </div>
      <div class="col-lg-6 pl-25">
        <base-input
          class="w-100 companyAddPage__input"
          v-model="companyInformation.contact.last_name"
          :error="v$.companyInformation.contact.last_name.$error"
          :error-message="v$.companyInformation.contact.last_name.$errors"
        >
          {{ $t("lastName") }}
        </base-input>
        <base-input
          class="w-100 companyAddPage__input"
          v-model="companyInformation.contact.job_title"
        >
          {{ $t("jobTitle") }}
        </base-input>
        <div class="row">
          <div class="col-lg-6">
            <base-input
              class="w-100 companyAddPage__input"
              v-model="companyInformation.contact.mobile_number"
            >
              {{ $t("accountOverviewPageMobile") }}
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              class="w-100 companyAddPage__input"
              v-model="companyInformation.contact.phone_number"
            >
              {{ $t("accountOverviewPagePhone") }}
            </base-input>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <div
        v-show="!haveChanges"
        @click="getCompanyInformation()"
        class="servicesAddPage__cancel"
      >
        {{ $t("discardChanges") }}
      </div>
      <base-button @click="saveChanges()" class="companyAddPage__saveButton">
        {{ $t("accountOverviewPageSave") }}
      </base-button>
    </div>
  </div>
  <div v-if="show.billing" class="companyAddPage__companyInformation">
    <h5 class="companyAddPage__blockTitle">{{ $t("billingInformation") }}</h5>
    <div class="row">
      <div class="col-lg-6 pr-25">
        <base-input
          class="w-100 companyAddPage__input"
          v-model="companyInformation.billing.name"
          :error="v$.companyInformation.billing.name.$error"
          :error-message="v$.companyInformation.billing.name.$errors"
        >
          {{ $t("companyName") }}
        </base-input>
        <base-input
          class="w-100 companyAddPage__input"
          v-model="companyInformation.billing.country"
        >
          {{ $t("country") }}
        </base-input>
        <div class="row">
          <div class="col-lg-6">
            <base-input
              class="w-100 companyAddPage__input"
              v-model="companyInformation.billing.city"
            >
              {{ $t("city") }}
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              class="w-100 companyAddPage__input"
              v-model="companyInformation.billing.postal_code"
            >
              {{ $t("postalCode") }}
            </base-input>
          </div>
        </div>
        <base-input
          class="w-100 companyAddPage__input"
          v-model="companyInformation.billing.address"
        >
          {{ $t("accountOverviewPageAddress") }}
        </base-input>
        <div class="row">
          <div class="col-lg-6">
            <base-input
              class="w-100 companyAddPage__input"
              v-model="companyInformation.billing.vat_number"
            >
              № {{ $t("vat") }}
            </base-input>
          </div>
        </div>
      </div>
      <div class="col-lg-6 pl-25">
        <base-input
          class="w-100 companyAddPage__input"
          v-model="companyInformation.billing.email"
          :error="v$.companyInformation.billing.email.$error"
          :error-message="v$.companyInformation.billing.email.$errors"
        >
          {{ $t("accountOverviewPageEmail") }}
        </base-input>
        <div class="row">
          <div class="col-lg-6">
            <base-input
              class="w-100 companyAddPage__input"
              v-model="companyInformation.billing.first_name"
              :error="v$.companyInformation.billing.first_name.$error"
              :error-message="v$.companyInformation.billing.first_name.$errors"
            >
              {{ $t("firstName") }}
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              class="w-100 companyAddPage__input"
              v-model="companyInformation.billing.last_name"
              :error="v$.companyInformation.billing.last_name.$error"
              :error-message="v$.companyInformation.billing.last_name.$errors"
            >
              {{ $t("lastName") }}
            </base-input>
          </div>
        </div>
        <base-input
          class="w-100 companyAddPage__input"
          v-model="companyInformation.contact.job_title"
        >
          {{ $t("jobTitle") }}
        </base-input>
        <div class="row">
          <div class="col-lg-6">
            <div class="accountPage__input companyAddPage__input">
              <span
                :class="{
                  'baseInput__area-focus': companyInformation.billing.gender,
                }"
                class="baseInput__label"
              >
                {{ $t("accountOverviewPageGender") }}
              </span>
              <vue-multiselect
                class="accountOverviewMultiselect"
                :options="genderOptions"
                v-model="companyInformation.billing.gender"
                :show-labels="false"
                placeholder=""
                openDirection="bottom"
                label="name"
                track-by="name"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <base-input
              class="w-100 companyAddPage__input"
              v-model="companyInformation.billing.office_number"
            >
              {{ $t("accountOverviewPageOffice") }}
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <base-input
              class="w-100 companyAddPage__input"
              v-model="companyInformation.billing.mobile_number"
            >
              {{ $t("accountOverviewPageMobile") }}
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              class="w-100 companyAddPage__input"
              v-model="companyInformation.billing.phone_number"
            >
              {{ $t("accountOverviewPagePhone") }}
            </base-input>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <div
        v-show="!haveChanges"
        @click="getCompanyInformation()"
        class="servicesAddPage__cancel"
      >
        {{ $t("discardChanges") }}
      </div>
      <base-button @click="saveChanges()" class="companyAddPage__saveButton">
        {{ $t("accountOverviewPageSave") }}
      </base-button>
    </div>
  </div>
  <div v-if="show.platform" class="accountPage__companyPlatform">
    <h5>{{ $t("platformSubscription") }}</h5>
    <div class="row">
      <div class="col-lg-6">
        <div class="accountPage__companyTitle">
          {{ $t("subscriptionPrice") }}
        </div>
        <div class="accountPage__companyContent">
          {{ companyInformation.subscription.price }}{{ $t("perMonth") }}
        </div>
      </div>
      <div class="col-lg-6">
        <div class="accountPage__companyTitle">
          {{ $t("nextBillingDate") }}
        </div>
        <div class="accountPage__companyContent">
          <data-formatted
            date-format="dd/MM/yyyy"
            :data="companyInformation.subscription.valid_from"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="accountPage__companyTitle">{{ $t("contract") }} №</div>
        <div class="accountPage__companyContent">
          {{ companyInformation.subscription.contract_code }}
        </div>
      </div>
    </div>
    <h5>{{ $t("platformParameter") }}</h5>
    <div class="row">
      <div class="col-lg-6">
        <div class="accountPage__companyTitle">
          {{ $t("DomainEnterprise") }}
        </div>
        <div class="accountPage__companyContent">
          {{ companyInformation.parameter.domain }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="accountPage__companyTitle">
          {{ $t("participationEnterprise") }}
        </div>
        <div class="accountPage__companyContent">
          {{ companyInformation.parameter.payment_percentage }}%
        </div>
      </div>
      <div class="col-lg-6">
        <div class="accountPage__companyTitle">
          {{ $t("participationEnterprise") }}
        </div>
        <div class="accountPage__companyContent">
          €{{
            `${companyInformation.parameter.payment_limit}${$t("perMonth")}`
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton";
import VueMultiselect from "vue-multiselect";
import DataLastSeen from "@/components/components-helpers/DataLastSeen";
import { mapGetters } from "vuex";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import CompanyLogo from "@/components/UI/images/CompanyLogo";
import BaseInput from "@/components/UI/inputs/BaseInput";
import { format } from "date-fns";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { clearString } from "@/helpers/clearStringToNumber";

export default {
  name: "CompanyAccountPage",
  components: {
    DataFormatted,
    BaseInput,
    CompanyLogo,
    HorizontalArrow,
    DataLastSeen,
    BaseButton,
    VueMultiselect,
  },
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();

    return {
      toast,
      v$,
    };
  },
  data() {
    return {
      isLoading: false,
      haveChanges: null,
      show: {
        overview: true,
        billing: false,
        platform: false,
        code: false,
      },
      informationBackups: {},
      thumbnail: "",
      oldImage: "",
      companyActivitiesOptions: [],
      jobTitleOptions: [],
      genderOptions: [],
      languageOptions: [],
      companyInformation: {
        name: "",
        number_of_employees: "",
        activity: "",
        code: "",
        vat_number: "",
        country: "",
        city: "",
        postal_code: "",
        address: "",
        job_title: "",
        language: "",
        user: {
          language_id: "",
          last_seen: "",
          email: "",
          password: "",
        },
        subscription: {
          contract_code: "",
          price: "",
          valid_from: "",
          valid_until: "",
        },
        parameter: {
          domain: "",
          money_spent: "",
          payment_limit: "",
          payment_percentage: "",
        },
        contact: {
          first_name: "",
          last_name: "",
          email: "",
          mobile_number: "",
          office_number: "",
          phone_number: "",
          gender: {
            id: "",
            name: "",
          },
        },
        billing: {
          name: "",
          address: "",
          country: "",
          city: "",
          postal_code: "",
          vat_number: "",
          email: "",
          first_name: "",
          last_name: "",
          job_title: "",
          gender: "",
          office_number: "",
          mobile_number: "",
          phone_number: "",
        },
      },
    };
  },
  validations() {
    return {
      companyInformation: {
        name: { required },
        user: {
          email: { email, required },
        },
        contact: {
          first_name: { required },
          last_name: { required },
        },
        billing: {
          name: { required },
          email: { email, required },
          first_name: { required },
          last_name: { required },
        },
      },
    };
  },
  async mounted() {
    await this.getOptions();
    await this.getCompanyInformation();
  },
  methods: {
    changeLanguage(languageId) {
      this.$http.patch(`/users/language/${languageId.id}`).then(() => {
        this.toast.success(this.$t("successfulSaveData"));
        this.haveChanges = true;
        this.$router.push("/");
      });
    },
    async getOptions() {
      this.languageOptions = await this.$http
        .get("/platform-languages")
        .then(({ data }) => {
          return data.body;
        });
      let activities = await this.$http
        .get("/company-activities")
        .then(({ data }) => {
          return data.body;
        });
      activities.forEach((element) => {
        this.companyActivitiesOptions.push({
          id: element.id,
          name: element.translations[0].name,
        });
      });

      this.genderOptions = await this.$http.get("/genders").then(({ data }) => {
        return data.body;
      });
      this.jobTitleOptions = await this.$http
        .get("/employees-positions")
        .then(({ data }) => {
          return data.body;
        });
    },
    async getCompanyInformation() {
      let companyInformation = await this.$http
        .get("/companies/account")
        .then(({ data }) => {
          return data.body;
        });

      let info = {
        is_blocked: companyInformation.user.is_blocked,
        user_id: companyInformation.user_id,
        role_id: 3,
      };
      this.$store.commit("SET_EDIT_USER_INFORMATION", info);
      companyInformation.subscription.valid_until = format(
        new Date(companyInformation.subscription.valid_until),
        "dd MMM yyyy"
      );
      companyInformation.activity = this.companyActivitiesOptions.filter(
        (element) => element.id === companyInformation.activity_id
      );
      companyInformation.language = this.languageOptions.filter(
        (element) => element.id === companyInformation.user.language_id
      );
      this.companyInformation = companyInformation;
      this.informationBackups = companyInformation;
    },
    saveChanges() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      if (!this.imageIsLoad) {
        this.isLoading = true;
        this.$store.commit("SET_LOADED_STATE", true);
        this.$http
          .patch("companies/account", {
            user_id: this.companyInformation.user_id,
            name: this.companyInformation.name,
            code: this.companyInformation.code,
            vat_number: this.companyInformation.vat_number,
            activity_id: this.companyInformation.activity.id,
            country: this.companyInformation.country,
            city: this.companyInformation.city,
            postal_code: this.companyInformation.postal_code,
            address: this.companyInformation.address,
            number_of_employees: Number(
              this.companyInformation.number_of_employees
            ),
            logo: this.companyInformation.logo,
            profile: {
              language_id: this.companyInformation.language.id,
            },
            user: {
              email: this.companyInformation.user.email,
            },
            contact: {
              gender_id: this.companyInformation.contact.gender
                ? this.companyInformation.contact.gender.id
                : null,
              first_name: this.companyInformation.contact.first_name,
              last_name: this.companyInformation.contact.last_name,
              job_title: this.companyInformation.contact.job_title,
              mobile_number: this.companyInformation.contact.mobile_number,
              phone_number: this.companyInformation.contact.phone_number,
              office_number: this.companyInformation.contact.office_number,
            },
            billing: {
              name: this.companyInformation.billing.name,
              country: this.companyInformation.billing.country,
              city: this.companyInformation.billing.city,
              postal_code: this.companyInformation.billing.postal_code,
              address: this.companyInformation.billing.address,
              vat_number: this.companyInformation.billing.vat_number,
              gender_id: this.companyInformation.billing.gender
                ? this.companyInformation.billing.gender.id
                : null,
              first_name: this.companyInformation.billing.first_name,
              last_name: this.companyInformation.billing.last_name,
              job_title: this.companyInformation.billing.job_title,
              mobile_number: this.companyInformation.billing.mobile_number,
              phone_number: this.companyInformation.billing.phone_number,
              office_number: this.companyInformation.billing.office_number,
            },
            subscription: {
              contract_code: this.companyInformation.subscription.contract_code,
              valid_from: this.companyInformation.subscription.valid_from,
              valid_until: this.companyInformation.subscription.valid_until,
              price: clearString(this.companyInformation.subscription.price),
            },
            parameter: {
              domain: this.companyInformation.parameter.domain,
              payment_percentage: clearString(
                this.companyInformation.parameter.payment_percentage
              ),
              payment_limit: clearString(
                this.companyInformation.parameter.payment_limit
              ),
            },
          })
          .then(() => {
            this.isLoading = false;
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.success(this.$t("successfulSaveData"));
            this.haveChanges = true;
            this.$router.push("/");
          })
          .catch((err) => {
            if (err.response.data.status === 400) {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(err.response.data.message);
            } else {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(this.$t("NetworkError"));
            }
          });
      }
    },
    selectCategory(category) {
      for (let i in this.show) {
        this.show[i] = i === category;
      }
    },
    showLogoPopup() {
      this.$store.commit("SET_LOGO_POPUP", true);
    },
  },
  computed: {
    ...mapGetters({
      popupsInformation: "STATE_POPUP",
      companyThumbnail: "THUMBNAIL",
      imageIsLoad: "LOADED_STATE",
    }),
  },
  watch: {
    popupsInformation: {
      deep: true,
      handler(newCount) {
        if (newCount.save) {
          this.saveChanges();
          this.haveChanges = true;
          this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
          this.$router.push(newCount.to);
        }
        if (newCount.leave) {
          this.haveChanges = true;
          this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
          this.$router.push(newCount.to);
        }
      },
    },
    companyThumbnail: {
      deep: true,
      handler(value) {
        this.$http
          .patch("companies/account/image", {
            image: value,
          })
          .then(() => {
            this.getCompanyInformation();
          });
      },
    },
    companyInformation: {
      deep: true,
      handler(newCount) {
        this.haveChanges =
          JSON.stringify(this.informationBackups) === JSON.stringify(newCount);
        this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
      },
    },
  },
};
</script>

<style></style>
