<template>
  <div class="d-flex align-items-center mb-30">
    <base-button @click="$router.back()" :back="true">
      <horizontal-arrow />
    </base-button>
    <h1 class="m-0 ms-3">
      {{ `${userInformation.first_name} ${userInformation.last_name}` }}
    </h1>
  </div>
  <div v-if="steps[0]" class="accountPage__infoContainer">
    <div class="accountPage__header">
      <div @click="showLogoPopup()" class="avatar-hovering-editAccount">
        <avatar
          v-if="isLoaded"
          user-role="experts"
          :user-code="userInformation.code"
          :image-name="userInformation.image"
          :edit-account="true"
          size="100"
          height="100px"
          width="100px"
        />
      </div>
      <div class="accountPage__info">
        <div class="accountPage__name">
          {{ `${userInformation.first_name} ${userInformation.last_name}` }}
        </div>
        <div class="accountPage__code">{{ userInformation.code }}</div>
      </div>
      <div class="accountPage__language">
        <vue-multiselect
          class="accountOverviewMultiselect languageSelect"
          v-if="userInformation.language"
          @select="changeLanguage"
          :options="languageOptions"
          v-model="userInformation.language"
          :searchable="false"
          :show-labels="false"
          placeholder=""
          openDirection="bottom"
          label="code"
        />
      </div>
    </div>
    <base-button @click="changePassword()" :book="true"
      >{{ $t("accountOverviewPagePassword") }}
    </base-button>
  </div>
  <div class="accountPage__steps">
    <div
      class="accountPage__step"
      :class="{ 'expertAddPage__steps-active': steps[0] }"
      @click="changeStep(0)"
    >
      {{ $t("accountOverviewPageOverview") }}
    </div>
    <div
      class="accountPage__step"
      :class="{ 'expertAddPage__steps-active': steps[1] }"
      @click="changeStep(1)"
    >
      {{ $t("Biography") }}
    </div>
    <div
      class="accountPage__step"
      :class="{ 'expertAddPage__steps-active': steps[2] }"
      @click="changeStep(2)"
    >
      {{ $t("sidebarServicesBtn") }}
    </div>
  </div>
  <div v-if="steps[0]" class="accountPage">
    <h5 class="mb-30">
      {{ $t("expertInformation") }}
    </h5>
    <div class="row">
      <div class="col-12 col-lg-6">
        <base-input
          class="expertAddPage__input"
          v-model="userInformation.first_name"
          type="text"
          :error="v$.userInformation.first_name.$error"
          :error-message="v$.userInformation.first_name.$errors"
        >
          {{ $t("accountOverviewPageName") }}
        </base-input>
        <base-input
          class="expertAddPage__input"
          v-model="userInformation.last_name"
          type="text"
          :error="v$.userInformation.last_name.$error"
          :error-message="v$.userInformation.last_name.$errors"
        >
          {{ $t("accountOverviewPageSurname") }}
        </base-input>
        <base-input
          class="expertAddPage__input"
          v-model="userInformation.languageString"
          :autocomplete="true"
          read-only="readonly"
        >
          {{ $t("languages") }}
        </base-input>
      </div>
      <div class="col-12 col-lg-6">
        <div class="d-flex justify-content-between">
          <div class="expertAddPage__input accountPage__input-small">
            <label class="expertAddPage__input-label">{{
              $t("accountOverviewPageGender")
            }}</label>
            <vue-multiselect
              :options="genderOptions"
              v-model="userInformation.gender"
              :placeholder="$t('selectGender')"
              label="name"
              track-by="name"
            />
          </div>
          <div class="expertAddPage__input expertAddPage__input-small">
            <label class="expertAddPage__input-label">{{
              $t("accountOverviewPageBirth")
            }}</label>
            <datepicker
              inputClassName="expertAccountPage__datePicker"
              v-model="userInformation.birthdate"
              format="MM/dd/yyyy"
              :enableTimePicker="false"
              autoApply
              textInput
              utc
            />
          </div>
        </div>
        <div class="accountPage__input accountPage__input-mail">
          <base-input
            class="expertAddPage__input"
            v-model="userInformation.email"
            type="email"
            :error="v$.userInformation.email.$error"
            :error-message="v$.userInformation.email.$errors"
          >
            {{ $t("accountOverviewPageEmail") }}
          </base-input>
        </div>
      </div>
    </div>
    <div class="row">
      <h5 class="mb-30">{{ $t("contactInformation") }}</h5>
      <div class="col-12 col-lg-6">
        <base-input
          class="w-100 expertAddPage__input"
          v-model="userInformation.country"
        >
          {{ $t("country") }}
        </base-input>
        <div class="row">
          <div class="col-lg-6">
            <base-input
              class="w-100 expertAddPage__input"
              v-model="userInformation.city"
            >
              {{ $t("city") }}
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              class="w-100 expertAddPage__input"
              v-model="userInformation.postal_code"
            >
              {{ $t("postalCode") }}
            </base-input>
          </div>
        </div>
        <base-input
          class="w-100 expertAddPage__input"
          v-model="userInformation.address"
        >
          {{ $t("accountOverviewPageAddress") }}
        </base-input>
      </div>
      <div class="col-12 col-lg-6">
        <base-input
          class="w-100 expertAddPage__input"
          v-model="userInformation.office_number"
          type="text"
        >
          {{ $t("accountOverviewPageOffice") }}
        </base-input>
        <base-input
          class="w-100 expertAddPage__input"
          v-model="userInformation.mobile_number"
          type="text"
        >
          {{ $t("accountOverviewPageMobile") }}
        </base-input>
        <base-input
          class="w-100 expertAddPage__input mb-0"
          v-model="userInformation.phone_number"
          type="text"
        >
          {{ $t("accountOverviewPagePhone") }}
        </base-input>
      </div>
    </div>
    <div class="accountPage__footer justify-content-end">
      <div class="accountPage__buttons">
        <base-button
          :is-loaded="imageIsLoad"
          @click="saveChanges(userInformation)"
          :book="true"
        >
          {{ $t("accountOverviewPageSave") }}
        </base-button>
      </div>
    </div>
  </div>
  <div v-if="steps[1]" class="row">
    <div class="accountPage__biography">
      <h5>{{ $t("shortBiography") }}</h5>
      <span v-html="userInformation.short_biography"></span>
    </div>
    <div class="accountPage__biography">
      <h5>{{ $t("fullBiography") }}</h5>
      <span v-html="userInformation.biography"></span>
    </div>
  </div>
  <div v-if="steps[2]">
    <div
      v-for="service in userInformation.services"
      :key="service.id"
      class="accountPage__expertService"
    >
      <h5>{{ service.name }}</h5>
      <div
        v-for="consultations in service.consultations"
        :key="consultations.id"
        class="row accountPage__expertServiceType"
      >
        <div class="col-lg-3">
          <div>{{ $t("productName") }}</div>
          <div>{{ consultations.name }}</div>
        </div>
        <div class="col-lg-3">
          <div>{{ $t("servicePrice") }}, €</div>
          <div>€{{ consultations.price }}</div>
        </div>
        <div class="col-lg-3">
          <div>{{ $t("income") }}, €</div>
          <div>€{{ consultations.income }}</div>
        </div>
        <div class="col-lg-3">
          <div>{{ $t("durationMin") }}</div>
          <div>{{ consultations.duration }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import BaseButton from "@/components/UI/buttons/BaseButton";
import BaseInput from "@/components/UI/inputs/BaseInput";
import Avatar from "@/components/UI/images/Avatar";
import LogoutIcon from "@/components/UI/icons/addRemoveIcons/LogoutIcon";
import WorkingHoursIcon from "@/components/UI/icons/WorkingHoursIcon";
import InformationIcon from "@/components/UI/icons/buttonIcons/InformationIcon.vue";
import PaymentMethod from "@/components/elements/card/PaymentMethod";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import Datepicker from "@vuepic/vue-datepicker";
import { mapGetters } from "vuex";
import { email, required } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";

export default {
  components: {
    HorizontalArrow,
    PaymentMethod,
    InformationIcon,
    WorkingHoursIcon,
    LogoutIcon,
    Avatar,
    BaseInput,
    BaseButton,
    VueMultiselect,
    Datepicker,
  },
  name: "ExpertAccountOverviewPage",
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();

    return {
      toast,
      v$,
    };
  },
  data() {
    return {
      isLoaded: false,
      havePayment: false,
      haveChanges: null,
      userInformation: [],
      informationBackups: [],
      languageOptions: [],
      genderOptions: [],
      steps: [true, false, false],
      step: 0,
    };
  },
  validations() {
    return {
      userInformation: {
        first_name: { required },
        last_name: { required },
        email: { email, required },
      },
    };
  },
  async mounted() {
    await this.getOptions();
    await this.getFullInformation();
  },
  methods: {
    changeStep(step) {
      for (let i in this.steps) {
        this.steps[i] = false;
      }
      this.steps[step] = true;
    },
    customLabel({ translations }) {
      return `${translations[0].name}`;
    },
    async getFullInformation() {
      this.isLoaded = false;
      const information = await this.$http
        .get("/experts/account")
        .then(({ data }) => {
          return data.body;
        });
      this.userInformation = information;
      this.userInformation.languageString = [];
      information.languages.forEach((lang) => {
        this.userInformation.languageString.push(lang.name);
      });
      this.userInformation.languageString =
        this.userInformation.languageString.join(", ");
      this.userInformation.language = this.languageOptions.filter(
        (element) =>
          element.id ===
          this.$store.getters.CURRENT_USER_INFORMATION.language_id
      )[0];
      this.userInformation.gender = this.genderOptions.filter(
        (element) => element.id === information.gender_id
      )[0];
      this.informationBackups = { ...this.userInformation };
      this.isLoaded = true;
    },
    async getOptions() {
      this.genderOptions = await this.$http.get("/genders").then(({ data }) => {
        return data.body;
      });
      this.languageOptions = await this.$http
        .get("/platform-languages", {})
        .then(({ data }) => {
          return data.body;
        });
    },
    deactivateAccount(value) {
      let accountActive = !value.is_active;
      this.$http
        .patch("/experts/" + value.user_id + "/is-active", {
          isActive: accountActive,
        })
        .then(() => {
          this.getFullInformation(value.code);
        });
    },
    changeLanguage(languageId) {
      this.$http.patch(`/users/language/${languageId.id}`).then(() => {
        this.haveChanges = true;
        this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
        this.$store.commit("SET_BLOG_ITEMS", []);
        this.$router.push("/");
      });
    },
    saveChanges(value) {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      this.isLoading = true;
      this.$store.commit("SET_LOADED_STATE", true);
      this.$http
        .patch("/experts/account", {
          gender_id: value.gender ? value.gender.id : null,
          first_name: value.first_name,
          last_name: value.last_name,
          birthdate: value.birthdate,
          country: value.country,
          city: value.city,
          postal_code: value.postal_code,
          address: value.address,
          mobile_number: value.mobile_number,
          phone_number: value.phone_number,
          office_number: value.office_number,
          user: {
            email: value.email,
          },
        })
        .then(() => {
          this.isLoading = false;
          this.$store.commit("SET_LOADED_STATE", false);
          this.toast.success(this.$t("successfulSaveData"));
          this.getCompanyInformation();
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(err.response.data.message);
          } else {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    showLogoPopup() {
      this.$store.commit("SET_LOGO_POPUP", true);
    },
    changePassword() {
      this.$store.commit("SET_CHANGE_EMPLOYEE_PASSWORD_POPUP", true);
    },
  },
  computed: {
    ...mapGetters({
      popupsInformation: "STATE_POPUP",
      thumbnail: "THUMBNAIL",
      imageIsLoad: "LOADED_STATE",
    }),
  },
  watch: {
    popupsInformation: {
      deep: true,
      handler(newCount) {
        if (newCount.save) {
          this.saveChanges(this.userInformation);
          this.haveChanges = true;
          this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
          this.$router.push(newCount.to);
        }
        if (newCount.leave) {
          this.haveChanges = true;
          this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
          this.$router.push(newCount.to);
        }
      },
    },
    thumbnail: {
      deep: true,
      handler(value) {
        this.isLoaded = false;
        this.$http
          .patch("/experts/account/image", {
            image: value,
          })
          .then(() => {
            this.getFullInformation();
          });
      },
    },
    userInformation: {
      deep: true,
      handler(newCount) {
        this.haveChanges =
          JSON.stringify(this.informationBackups) === JSON.stringify(newCount);
        this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
      },
    },
  },
};
</script>

<style></style>
