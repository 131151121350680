<template>
  <main-layout>
    <employee-account-overview-page v-if="loginUser.role_id === 4" />
    <company-account-page v-if="loginUser.role_id === 3" />
    <expert-account-overview-page v-if="loginUser.role_id === 2" />
    <admin-account-overview-page v-if="loginUser.role_id === 1" />
  </main-layout>
</template>

<script>
import { mapGetters } from "vuex";
import MainLayout from "@/layouts/MainLayout";
import EmployeeAccountOverviewPage from "@/components/pages-templates/account/EmployeeAccountOverviewPage";
import AdminAccountOverviewPage from "@/components/pages-templates/account/AdminAccountOverviewPage";
import ExpertAccountOverviewPage from "@/components/pages-templates/account/ExpertAccountOverviewPage";
import CompanyAccountPage from "@/components/pages-templates/account/CompanyAccountPage";

export default {
  name: "AccountPage",
  components: {
    CompanyAccountPage,
    ExpertAccountOverviewPage,
    AdminAccountOverviewPage,
    EmployeeAccountOverviewPage,
    MainLayout,
  },
  beforeRouteLeave(to, from, next) {
    if (!this.haveChanges) {
      let changesPopup = {
        show: true,
        to: to.path,
        save: null,
      };
      this.$store.commit("SET_STATE_POPUP", changesPopup);
    } else {
      return next();
    }
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
      haveChanges: "ACCOUNT_CHANGES",
    }),
  },
};
</script>

<style></style>
