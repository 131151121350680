<template>
  <div class="d-flex align-items-center">
    <base-button @click="$router.back()" :back="true">
      <horizontal-arrow />
    </base-button>
    <h1 class="m-0 ms-3">{{ $t("adminProfile") }}</h1>
  </div>
  <div class="adminAccountOverviewPage__infoWrapper">
    <avatar height="100px" width="100px" />
    <div class="adminAccountOverviewPage__infoText">
      <div class="adminAccountOverviewPage__name">
        {{ `${information.first_name} ${information.last_name}` }}
      </div>
      <div class="adminAccountOverviewPage__position">
        {{ $t("sidebarAdminName") }}
      </div>
    </div>
  </div>
  <div class="adminAccountOverviewPage__inputsWrapper">
    <div class="adminAccountOverviewPage__inputsTitle">
      {{ $t("adminInformation") }}
    </div>
    <base-input
      class="adminAccountOverviewPage__input"
      v-model="information.first_name"
      :error="v$.information.first_name.$error"
      :error-message="v$.information.first_name.$errors"
    >
      {{ $t("accountOverviewPageName") }}
    </base-input>
    <base-input
      class="adminAccountOverviewPage__input"
      v-model="information.last_name"
      :error="v$.information.last_name.$error"
      :error-message="v$.information.last_name.$errors"
    >
      {{ $t("accountOverviewPageSurname") }}
    </base-input>
    <base-input
      v-if="information.user"
      class="adminAccountOverviewPage__input"
      v-model="information.user.email"
      :error="v$.information.user.email.$error"
      :error-message="v$.information.user.email.$errors"
    >
      {{ $t("accountOverviewPageEmail") }}
    </base-input>
    <div class="adminAccountOverviewPage__footer">
      <div
        v-if="!haveChanges"
        @click="getFullInformation()"
        class="passwordResetPopup-cancel"
      >
        {{ $t("discardChanges") }}
      </div>
      <base-button :is-loaded="imageIsLoad" @click="updInformation()"
        >{{ $t("accountOverviewPageSave") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import BaseInput from "@/components/UI/inputs/BaseInput";
import Avatar from "@/components/UI/images/Avatar";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "AdminAccountOverviewPage",
  components: { Avatar, BaseInput, HorizontalArrow, BaseButton },
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();

    return {
      toast,
      v$,
    };
  },
  data() {
    return {
      information: {},
      informationBackups: {},
      isLoaded: false,
      haveChanges: true,
    };
  },
  validations() {
    return {
      information: {
        first_name: { required },
        last_name: { required },
        user: { email: { required } },
      },
    };
  },
  mounted() {
    this.getFullInformation();
  },
  methods: {
    async getFullInformation() {
      this.information = await this.$http
        .get("/administrators/account")
        .then(({ data }) => {
          return data.body;
        });
      let userInformation = {
        user_id: this.currentUser.id,
        email: this.currentUser.email,
        role_id: this.currentUser.role_id,
      };
      this.$store.commit("SET_EDIT_USER_INFORMATION", userInformation);
      this.informationBackups = { ...this.information };
    },
    updInformation() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }

      if (!this.imageIsLoad) {
        this.$store.commit("SET_LOADED_STATE", true);
        this.$http
          .patch("/administrators/account", {
            first_name: this.information.first_name,
            last_name: this.information.last_name,
            user: {
              email: this.information.user.email,
            },
          })
          .then(() => {
            this.haveChanges = true;
            this.$store.commit("SET_LOADED_STATE", false);
            this.$store.commit("SET_ACCOUNT_CHANGES", true);
            this.toast.success(this.$t("successfulSaveData"));
          })
          .catch((err) => {
            if (err.response.data.status === 400) {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(err.response.data.message);
            } else {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(this.$t("NetworkError"));
            }
          });
      }
    },
  },
  computed: {
    ...mapGetters({
      popupsInformation: "STATE_POPUP",
      imageIsLoad: "LOADED_STATE",
      currentUser: "CURRENT_USER_INFORMATION",
    }),
  },
  watch: {
    popupsInformation: {
      deep: true,
      handler(newCount) {
        if (newCount.save) {
          this.updInformation();
          this.haveChanges = true;
          this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
          this.$router.push(newCount.to);
        }
        if (newCount.leave) {
          this.haveChanges = true;
          this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
          this.$router.push(newCount.to);
        }
      },
    },
    information: {
      deep: true,
      handler(newCount) {
        this.haveChanges =
          JSON.stringify(this.informationBackups) === JSON.stringify(newCount);
        this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
      },
    },
  },
};
</script>

<style></style>
