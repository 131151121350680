<template>
  <h1>{{ userInformation.first_name + " " + userInformation.last_name }}</h1>
  <div v-if="steps[0]" class="accountPage__infoContainer">
    <div class="accountPage__header">
      <avatar
        v-if="isLoaded"
        :edit-account="true"
        user-role="employees"
        size="100"
        :user-code="userInformation.code"
        :image-name="userInformation.image"
        @click="showLogoPopup()"
        class="avatar-hovering-editAccount"
        height="100px"
        width="100px"
      />
      <div class="accountPage__info">
        <div class="accountPage__name">
          {{ userInformation.first_name + " " + userInformation.last_name }}
        </div>
        <div class="accountPage__code">{{ userInformation.code }}</div>
      </div>
      <div class="accountPage__language">
        <vue-multiselect
          class="accountOverviewMultiselect languageSelect"
          v-if="userInformation.language"
          @select="changeLanguage"
          :options="languageOptions"
          v-model="userInformation.language"
          :searchable="false"
          :show-labels="false"
          placeholder=""
          openDirection="bottom"
          label="code"
        />
      </div>
    </div>
    <base-button @click="changePassword()" :book="true"
      >{{ $t("accountOverviewPagePassword") }}
    </base-button>
  </div>
  <div class="accountPage__steps">
    <div
      class="accountPage__step"
      :class="{ 'expertAddPage__steps-active': steps[0] }"
      @click="changeStep(0)"
    >
      {{ $t("accountOverviewPageOverview") }}
    </div>
    <div
      class="accountPage__step"
      :class="{ 'expertAddPage__steps-active': steps[1] }"
      @click="changeStep(1)"
    >
      {{ $t("accountOverviewPagePayment") }}
    </div>
  </div>
  <div v-if="steps[0]">
    <div class="accountPage">
      <div class="row mt-20">
        <div class="col-12 col-lg-6 pr-25">
          <base-input
            class="w-100 accountPage__input"
            type="text"
            v-model="userInformation.first_name"
            :error="v$.userInformation.first_name.$error"
            :error-message="v$.userInformation.first_name.$errors"
          >
            {{ $t("accountOverviewPageName") }}
          </base-input>
          <base-input
            class="w-100 accountPage__input"
            type="text"
            v-model="userInformation.last_name"
            :error="v$.userInformation.last_name.$error"
            :error-message="v$.userInformation.last_name.$errors"
          >
            {{ $t("accountOverviewPageSurname") }}
          </base-input>
          <base-input
            class="w-100 accountPage__input"
            type="email"
            v-model="userInformation.email"
            :error="v$.userInformation.email.$error"
            :error-message="v$.userInformation.email.$errors"
          >
            {{ $t("accountOverviewPageEmail") }}
          </base-input>
        </div>
        <div class="col-12 col-lg-6 pl-25">
          <div class="d-flex justify-content-between">
            <div class="accountPage__input accountPage__input-small">
              <span
                :class="{ 'baseInput__area-focus': userInformation.gender }"
                class="baseInput__label"
              >
                {{ $t("accountOverviewPageGender") }}
              </span>
              <vue-multiselect
                class="accountOverviewMultiselect"
                :options="genderOptions"
                v-model="userInformation.gender"
                :show-labels="false"
                placeholder=""
                openDirection="bottom"
                label="name"
                track-by="name"
              />
            </div>
            <div class="accountPage__input accountPage__input-small">
              <base-input
                class="w-100"
                type="date"
                v-model="userInformation.birthdate"
                >{{ $t("accountOverviewPageBirth") }}
              </base-input>
            </div>
          </div>
          <div class="accountPage__input">
            <span
              :class="{ 'baseInput__area-focus': userInformation.position }"
              class="baseInput__label"
            >
              {{ $t("accountOverviewPagePosition") }}*
            </span>
            <vue-multiselect
              class="accountOverviewMultiselect"
              :options="positionOptions"
              v-model="userInformation.position"
              :show-labels="false"
              placeholder=""
              openDirection="bottom"
              label="name"
              track-by="name"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <h5 class="employeesSinglePage__title mb-50">
          {{ $t("contactInformation") }}
        </h5>
        <div class="col-12 col-lg-6 pr-25">
          <base-input
            class="w-100 accountPage__input"
            v-model="userInformation.country"
          >
            {{ $t("country") }}
          </base-input>
          <div class="row">
            <div class="col-lg-6">
              <base-input
                class="w-100 accountPage__input"
                v-model="userInformation.city"
              >
                {{ $t("city") }}
              </base-input>
            </div>
            <div class="col-lg-6">
              <base-input
                class="w-100 accountPage__input"
                v-model="userInformation.postal_code"
              >
                {{ $t("postalCode") }}
              </base-input>
            </div>
          </div>
          <base-input
            class="w-100"
            v-model="userInformation.address"
            type="text"
          >
            {{ $t("accountOverviewPageAddress") }}
          </base-input>
        </div>
        <div class="col-12 col-lg-6 pl-25">
          <base-input
            class="w-100 accountPage__input"
            v-model="userInformation.office_number"
            type="text"
          >
            {{ $t("accountOverviewPageOffice") }}
          </base-input>
          <base-input
            class="w-100 accountPage__input"
            v-model="userInformation.mobile_number"
            type="text"
          >
            {{ $t("accountOverviewPageMobile") }}
          </base-input>
          <base-input
            class="w-100 accountPage__input mb-0"
            v-model="userInformation.phone_number"
            type="text"
          >
            {{ $t("accountOverviewPagePhone") }}
          </base-input>
        </div>
      </div>
      <div class="accountPage__footer">
        <div class="accountPage__buttons">
          <router-link to="/" class="accountPage__cancel"
            >{{ $t("cancel") }}
          </router-link>
          <base-button
            :is-loaded="imageIsLoad"
            @click="saveChanges(userInformation)"
            :book="true"
          >
            {{ $t("accountOverviewPageSave") }}
          </base-button>
        </div>
      </div>
    </div>
    <div class="accountPage__wellnessContainer">
      <h5>{{ $t("accountOverviewPageWellness") }}</h5>
      <div>
        {{ $t("accountOverviewPageNewsletter") }}
      </div>
      <div class="accountPage__wellnessBtn">
        <base-button @click="newsletter(subscribe)"
          >{{
            subscribe ? $t("unsubscribe") : $t("accountOverviewPageSubscribe")
          }}
        </base-button>
      </div>
    </div>
  </div>
  <div v-if="steps[1]" class="row">
    <div v-if="havePayment" class="col-12 col-lg-6 accountPage__payment">
      <h5>{{ $t("accountOverviewPagePayment") }}</h5>
      <div class="accountPage__paymentText">
        {{ $t("accountOverviewPagePaymentText") }}
      </div>
      <div class="accountPage__paymentCart">
        <div class="accountPage__paymentCartWrapper">
          <div class="accountPage__paymentCart-number">
            **** **** **** {{ havePayment }}
          </div>
          <div class="accountPage__paymentCart-small">
            <span class="accountPage__paymentCart-bold"></span>
          </div>
          <div class="accountPage__paymentCart-small">
            <span class="accountPage__paymentCart-bold"></span>
          </div>
        </div>
      </div>
      <div class="accountPage__paymentText d-flex">
        <div>
          <information-icon />
        </div>
        <div class="accountPage__paymentText-margin">
          {{ $t("accountOverviewPagePaymentInfo") }}
        </div>
      </div>
      <div class="accountPage__paymentFooter">
        <div class="d-flex align-items-center cursor-pointer">
          <working-hours-icon />
          <router-link to="/transactions" class="accountPage__paymentHistory">
            {{ $t("accountOverviewPagePaymentHistoryBtn") }}
          </router-link>
        </div>
        <base-button
          @click="deletePaymentMethod()"
          class="cartPage__costAddCardBtn mt-0"
        >
          {{ $t("accountOverviewPagePaymentDeleteBtn") }}
        </base-button>
      </div>
    </div>
    <payment-method v-if="!havePayment" />
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import { format } from "date-fns";
import BaseButton from "@/components/UI/buttons/BaseButton";
import BaseInput from "@/components/UI/inputs/BaseInput";
import Avatar from "@/components/UI/images/Avatar";
import LogoutIcon from "@/components/UI/icons/addRemoveIcons/LogoutIcon";
import WorkingHoursIcon from "@/components/UI/icons/WorkingHoursIcon";
import InformationIcon from "@/components/UI/icons/buttonIcons/InformationIcon.vue";
import PaymentMethod from "@/components/elements/card/PaymentMethod";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";

export default {
  components: {
    PaymentMethod,
    InformationIcon,
    WorkingHoursIcon,
    LogoutIcon,
    Avatar,
    BaseInput,
    BaseButton,
    VueMultiselect,
  },
  name: "EmployeeAccountOverviewPage",
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();

    return {
      toast,
      v$,
    };
  },
  data() {
    return {
      isLoaded: true,
      subscribe: false,
      userInformation: [],
      informationBackups: [],
      languageOptions: [],
      genderOptions: [],
      positionOptions: [],
      steps: [true, false, false],
      step: 0,
    };
  },
  validations() {
    return {
      userInformation: {
        first_name: { required },
        last_name: { required },
        email: { email, required },
      },
    };
  },
  async mounted() {
    await this.getOptions();
    await this.getFullInformation();
    setTimeout(() => {
      if (
        this.$route.query.subscribe &&
        this.$route.query.subscribe === "true" &&
        !this.subscribe
      ) {
        this.newsletter(false);
      } else if (
        this.$route.query.subscribe &&
        this.$route.query.subscribe === "false" &&
        this.subscribe
      ) {
        this.newsletter(true);
      }
    }, 1000);
  },
  methods: {
    changeStep(step) {
      for (let i in this.steps) {
        this.steps[i] = false;
      }
      this.steps[step] = true;
    },
    async getFullInformation() {
      const information = await this.$http
        .get(
          "/employees/code/" + this.$store.getters.CURRENT_USER_INFORMATION.code
        )
        .then(({ data }) => {
          return data.body;
        });
      this.userInformation = information;
      this.userInformation.birthdate = format(
        new Date(information.birthdate),
        "yyyy-MM-dd"
      );
      this.userInformation.language = JSON.parse(
        JSON.stringify(
          this.languageOptions.filter(
            (element) => element.id === information.language_id
          )
        )
      )[0];
      this.userInformation.position = {
        id: this.userInformation.position.id,
        name: this.userInformation.position.translations[0].name,
      };
      this.getPaymentMethod();
      this.getSubscribeStatus();
      this.informationBackups = { ...this.userInformation };
      this.isLoaded = true;
    },
    async getOptions() {
      this.genderOptions = await this.$http.get("/genders").then(({ data }) => {
        return data.body;
      });
      this.languageOptions = await this.$http
        .get("/platform-languages")
        .then(({ data }) => {
          return data.body;
        });
      const positions = await this.$http
        .get("/employees-positions")
        .then(({ data }) => {
          return data.body;
        });
      positions.forEach((element) => {
        this.positionOptions.push({
          name: element.translations[0].name,
          id: element.id,
        });
      });
    },
    getPaymentMethod() {
      if (!this.havePayment) {
        this.$http.get("/employees/account/card").then(({ data }) => {
          this.$store.commit("SET_PAYMENT", data.body);
        });
      }
    },
    deletePaymentMethod() {
      this.$http.delete("/employees/detach-card").then(() => {
        this.toast.success(this.$t("successfulSaveData"));
        this.$store.commit("SET_PAYMENT", null);
        this.getFullInformation();
      });
    },
    getSubscribeStatus() {
      this.$http.get("/newsletters/wellness").then(({ data }) => {
        this.subscribe = data.body;
      });
    },
    newsletter(subscribeStatus) {
      if (subscribeStatus) {
        this.$http.delete("/newsletters/wellness/unsubscribe").then(() => {
          this.subscribe = false;
          this.toast.success(
            this.$t("YouHaveSuccessfullyUnsubscribedNewsletter")
          );
        });
      } else {
        this.$http.post("/newsletters/wellness/subscribe").then(() => {
          this.subscribe = true;
          this.toast.success(
            this.$t("YouHaveSuccessfullySubscribedNewsletter")
          );
        });
      }
    },
    changeLanguage(languageId) {
      this.$http.patch(`/users/language/${languageId.id}`).then(() => {
        this.haveChanges = true;
        this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
        this.$store.commit("SET_BLOG_ITEMS", null);
        this.$store.commit("SET_BLOG_LATEST_POST", null);
        this.$store.commit("SET_BLOG_TAGS", []);
        this.$router.push("/");
      });
    },
    saveChanges(value) {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      if (!this.imageIsLoad) {
        this.$store.commit("SET_LOADED_STATE", true);
        this.$http
          .patch("/employees/account", {
            employee: {
              gender_id: value.gender ? value.gender.id : null,
              position_id: value.position ? value.position.id : null,
              first_name: value.first_name,
              last_name: value.last_name,
              country: value.country,
              city: value.city,
              postal_code: value.postal_code,
              address: value.address,
              mobile_number: value.mobile_number,
              phone_number: value.phone_number,
              office_number: value.office_number,
              birthdate: value.birthdate,
            },
            profile: {
              language_id: value.language.id,
            },
            user: {
              email: value.email,
            },
          })
          .then(() => {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.success(this.$t("successfulSaveData"));
          })
          .catch((err) => {
            if (err.response.data.status === 400) {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(err.response.data.message);
            } else {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(this.$t("NetworkError"));
            }
          });
      }
    },
    changePassword() {
      this.$store.commit("SET_CHANGE_EMPLOYEE_PASSWORD_POPUP", true);
    },
    showLogoPopup() {
      this.$store.commit("SET_LOGO_POPUP", true);
    },
  },
  computed: {
    ...mapGetters({
      popupsInformation: "STATE_POPUP",
      thumbnail: "THUMBNAIL",
      imageIsLoad: "LOADED_STATE",
      havePayment: "PAYMENT",
    }),
  },
  watch: {
    popupsInformation: {
      deep: true,
      handler(newCount) {
        if (newCount.save) {
          this.saveChanges(this.userInformation);
          this.haveChanges = true;
          this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
          this.$router.push(newCount.to);
        }
        if (newCount.leave) {
          this.haveChanges = true;
          this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
          this.$router.push(newCount.to);
        }
      },
    },
    thumbnail: {
      deep: true,
      handler(value) {
        this.isLoaded = false;
        this.$http
          .patch("/employees/account/image", {
            image: value,
          })
          .then(() => {
            this.getFullInformation();
          });
      },
    },
    userInformation: {
      deep: true,
      handler(newCount) {
        this.haveChanges =
          JSON.stringify(this.informationBackups) === JSON.stringify(newCount);
        this.$store.commit("SET_ACCOUNT_CHANGES", this.haveChanges);
      },
    },
  },
};
</script>

<style></style>
